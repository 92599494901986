import axios from 'axios'
import { Message } from 'element-ui'
import Router from '@/router'

export function exportResponseData(data, contentType, fileName) {
  const downloadLink = window.document.createElement('a')
  downloadLink.href = window.URL.createObjectURL(new Blob([data], { type: contentType }))
  downloadLink.download = fileName
  document.body.appendChild(downloadLink)
  downloadLink.click()
  document.body.removeChild(downloadLink)
}
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 30000
})
service.interceptors.request.use(config => {
  if (config.responseType === 'blob') {
    config.headers['responseType'] = 'blob'
  }
  config.headers['X-Token'] = getToken()
  return config
}, error => {
  // Do something with request error
  // console.log(error) // for debug
  Promise.reject(error)
})

// respone 响应 拦截器
service.interceptors.response.use(
  response => {
    /**
     * 判断是否为excel文件【主要代码】
     */
    if (response.config.isExportExcel) {
      const data = response.data
      const contentType = response.headers['content-type']
      let fileName
      if (response.headers['content-disposition']) {
        fileName = decodeURI(
          response.headers['content-disposition'].match(/filename=(.*)/)[1]
        )
      } else {
        fileName = dayjs().format('YYYYMMDD')
      }
      exportResponseData(data, contentType, fileName)
      return
    }
    /**
    * code为非20000是抛错 可结合自己业务进行修改
    */
    return response.data
  },
  error => {
  }
)
export const exportExcel = config => {
  return service({
    method: 'post',
    isExportExcel: true,
    responseType: 'blob',
    ...config
  })
}

export default service 