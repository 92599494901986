import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import ECharts from 'vue-echarts';
import vueSeamless from 'vue-seamless-scroll'
import * as echarts from 'echarts'
import router from '@/router/index.js'
import china from '@/assets/json/china'
// import "./plugins/element.js";

import AMapLoader from "@amap/amap-jsapi-loader";

AMapLoader.load({
  'key': '33ac7b728fe86dc24beafaab3ef61340',           
  'version': '2.0',   // 指定要加载的 JSAPI 的版本，缺少时默认为 1.4.15
  'plugins': ['AMap.Scale','AMap.GeoJSON']           // 需要使用的的插件列表，如比例尺'AMap.Scale'等，更多插件请看官方文档
}).then((AMap) => {
  Vue.use(AMap)
})

// import $ from 'jquery'
//全国客流流向图JSON
import qinghai from '@/assets/json/qinghai'
import fullscreen from 'vue-fullscreen'
import "./assets/style/global.scss";
import "./assets/style/mobile.scss";
// import SvgIcon from "./components/Svg/index.vue";

// 导入svg
// Vue.component("svg-icon", SvgIcon);
Vue.use(fullscreen)
// const requireAll = (requireContext) =>
//   requireContext.keys().map(requireContext);

// const req = require.context("@/assets/img/svg", true, /\.svg$/);
// requireAll(req);



Vue.config.productionTip = false;

//全国客流流向图
echarts.registerMap("china", china)
//全省客流热力图
echarts.registerMap("qinghai", qinghai)
//全市州景区热力图
echarts.registerMap("jingqu", qinghai)
//默认大屏标题
Vue.prototype.$screenTitle = {
  module1_1: "全省客流综合数据",
  module2_1: "国内客源地Top5占比",
  module2_2: "本年度省外客流量",
  module3: "国内旅客流量总览",
  module3_1: "全国客流流向图",
  module3_2: "全省客流热力图",
  module3_3: "市州景区热力图",
  module3_4: "今日实时入省客流",
  module4_1: "青海省铁路旅客运输统计",
  module4_2: "民航客流实时数据",
  module5_1: "游玩时长占比",
  module6_1: "交通路况",
  module7_1: "气象预警信息",
  module8_1: "投诉数据",
  module9_1: "指挥调度数据中心",
  module9_2: "指挥调度平台",
  module9_3: "更多",
  screen_title: "青海文旅行业指挥调度数据中心",
  screen_media_data_stat: '舆情数据',
  screen_consumption_rate: '文旅消费数据分析',
  middle_temp_word: '',
}
//菜单权限
Vue.prototype.$MenuAuth = {}
//按钮权限
Vue.prototype.$ButtonAuth = {}


Vue.config.productionTip = false
Vue.component('v-chart', ECharts);
Vue.use(ElementUI)
Vue.use(vueSeamless)
new Vue({
  router,
  render: function (h) { return h(App) },
}).$mount('#app')

