<template>
  <div class="navBar">
    <div class="topTitle">
      <img src="../../assets/pc/logo.png" style="height: 60px;margin-right: 10px;">
      数字文旅行业综合指挥调度平台
    </div>
    <div class="NavBtn">
      <div class="sidebar-container">
      </div>
    </div>
    <div class="right-menu">
      <span>{{ currentTime }}</span> | <span><i class="el-icon-user"></i> 用户：{{ UserName }}</span> <span
        @click="handleLogout" style="cursor: pointer"><i class="el-icon-switch-button"></i> 退出</span>
    </div>

  </div>
</template>

<script>
import { Message } from 'element-ui'
import { logout } from '@/api/complaint'
// import { mapGetters } from 'vuex'
import Breadcrumb from '@/components/Breadcrumb'
import variables from '@/styles/variables.scss'
export default {
  // data() {
  //   return {
  //     routes: [],
  //     UserName: 'admin',
  //   }
  // },
  components: {
    Breadcrumb,
  },
  computed: {
    routes() {
      return this.$router.options.routes
    },
    activeMenu() {
      const route = this.$route
      const { meta, path } = route
      if (meta.activeMenu) {
        return meta.activeMenu
      }
      return path
    },
    showLogo() {
      return this.$store.state.settings.sidebarLogo
    },
    variables() {
      return variables
    },
    isCollapse() {
      return !this.sidebar.opened
    },
    getNowFormatDate() {
      var date = new Date();
      var seperator1 = "-";
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var strDate = date.getDate();
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
      }
      var currentdate = year + seperator1 + month + seperator1 + strDate;
      return currentdate;
    },
  },
  data() {
    return {
      currentTime: '',
      activeIndex: '',
      UserName: '',
    }
  },
  mounted() {
    setInterval(() => {
      this.currentTime = this.GetDateTimeToString()
    }, 1000)
    const currentRoute = this.$route.path
    // console.log(this.$route.path)
    if (currentRoute == '/pc/list') {
      this.activeIndex = '1'
    } else if (currentRoute == '/onDuty/list') {
      this.activeIndex = '2'
    } else if (currentRoute == '/contact/list') {
      this.activeIndex = '3'
    } else if (currentRoute == '/warn/list') {
      this.activeIndex = '4'
    }
    // this.activeIndex = this.$route.path
  },
  methods: {
    GetDateTimeToString() {
      let name = localStorage.getItem('userName')
      if (name) {
        this.UserName = name
      }
      var date_ = new Date();
      var year = date_.getFullYear();
      var month = date_.getMonth() + 1;
      var day = date_.getDate();
      if (month < 10) month = "0" + month;
      if (day < 10) day = "0" + day;

      var hours = date_.getHours();
      var mins = date_.getMinutes();
      var secs = date_.getSeconds();
      var msecs = date_.getMilliseconds();
      if (hours < 10) hours = "0" + hours;
      if (mins < 10) mins = "0" + mins;
      if (secs < 10) secs = "0" + secs;
      if (msecs < 10) secs = "0" + msecs;
      return year + "-" + month + "-" + day + " " + hours + ":" + mins + ":" + secs;
    },
    toggleSideBar() {
      this.$store.dispatch('app/toggleSideBar')
    },
    async handleLogout() {
      this.$confirm('确认要退出吗').then(res => {
        logout().then(res => {
          localStorage.removeItem('token')
          localStorage.removeItem('userName')
          localStorage.removeItem('MenuAuth')
          localStorage.removeItem('ButtonAuth')
          Message({
            message: '退出成功',
            type: 'success',
            duration: 3 * 1000
          })
          this.$router.push({ path: '/login' });
        })
      })

      // window.location.replace("http://zhdd.qhswlt.com:9003/#/index"); 
    }
  }
}
</script>

<style lang="scss" scoped>
.navBar {
  height: 90px;
  overflow: hidden;
  position: relative;
  // background: #051642;
  // #
  display: flex;
  align-items: center;
  justify-content: space-between;
  /*background-image: linear-gradient(to right, #051642, #042f75);*/
  background: url("../../assets/banner.png") 100% 100% no-repeat;
  box-shadow: 0 1px 4px rgba(0, 21, 41, .08);

  .topTitle {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 15px;
    color: #fff;
    font-size: 26px;

    img {
      /*width: 56px;*/
      height: 56px;
    }

    .TopTitle {
      font-size: 24.415px;
      font-family: "Microsoft YaHei";
      color: #fff;
      line-height: 1.2;
      text-align: left;
      z-index: 159;
    }

  }

  .NavBtn {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    color: #ffffff;
    padding-right: 50px;
    background: url("../../assets/banner.png") 100% 100%;

    .BtnItem {
      font-size: 18px;
      min-width: 90px;
      height: 70px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: "Adobe Heiti Std";
      color: rgb(255, 255, 255);

    }

    .on {
      /*background: url('../../assets/goverment/g_navBg.png') no-repeat center bottom;*/
    }

  }

  .hamburger-container {
    line-height: 46px;
    height: 100%;
    float: left;
    cursor: pointer;
    transition: background .3s;
    -webkit-tap-highlight-color: transparent;

    &:hover {
      background: rgba(0, 0, 0, .025)
    }
  }

  .breadcrumb-container {
    float: left;
  }

  .right-menu {
    position: absolute;
    top: 0;
    right: 10px;
    // height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    line-height: 38px;
    font-size: 16px;
    color: #fff;

    span {
      margin: 0 10px;
      color: #fff;
    }

    &:focus {
      outline: none;
    }

    .NoticBtn {
      height: 35px;
      padding: 0px 10px;
    }

    .right-menu-item {
      display: inline-block;
      padding: 0 8px;
      height: 100%;
      font-size: 18px;
      color: #5a5e66;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
        transition: background .3s;

        &:hover {
          background: rgba(0, 0, 0, .025)
        }
      }
    }

    .avatar-container {
      .avatar-wrapper {
        display: flex;
        align-items: center;
        margin-top: 5px;
        position: relative;

        .user-avatar {
          cursor: pointer;
          width: 40px;
          height: 40px;
          border-radius: 10px;
          margin: 0px 10px;
        }

        .UserName {
          margin-right: 10px;
          color: #fff;
          font-size: 20px;
          font-family: '黑体';
          cursor: pointer;
        }

        .el-icon-caret-bottom {
          cursor: pointer;
          position: absolute;
          right: -20px;
          top: 25px;
          font-size: 12px;
        }
      }
    }

    img {
      width: 28px;
      height: 28px;
    }

    .right-menu-date {
      color: #fff;
      font-size: 16px;
    }

    .right-menu-help {
      padding: 0 10px;
      line-height: 25px;

      img {
        vertical-align: middle;
        width: 24px;
        height: 24px;
      }
    }
  }
}

.el-menu-item {
  display: flex;
  align-items: center;
}

.el-menu-item>.el-tooltip {
  padding: 0px 17px 0px 0px !important;
  width: 100%;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.el-menu--collapse>.menu-wrapper>.el-submenu>.el-submenu__title {
  padding: 0px 17px 0px 0px !important;
  width: 100%;
  display: flex !important;
  justify-content: center;
  align-items: center;
}



.submenu-title-noDropdown>span {
  padding-top: 30px;
}

.el-menu-item>span {
  /*color:#333!important;*/
}

.sidebar-container {
  background-color: #bfa;
}

.el-menu--vertical {
  background-color: #3842af;
  // #app .sidebar-container .scrollbar-wrapper
}
</style>
