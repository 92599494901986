

import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)
import { userInfo } from '@/api/complaint'
import Index from '@/views/IndexView.vue'
import { Message } from 'element-ui'
export const constantRoutes = [

	{
		path: '/',
		redirect: '/navigate',
		hide: true
	},

	{
		hide: true,
		path: '/navigate',
		name: '导航页', 
		component: () => import('@/views/login/navigate'),
	},
	{
		hide: true,
		path: '/home',
		name: '驾驶舱',
		icon: 'warn',
		component: () => import('@/views/home/index'),
		meta: {
			auth: 1
		}
	},
	{
		hide: true,
		path: '/dispatch',
		name: '调度大屏',
		icon: 'warn',
		component: () => import('@/views/dispatch/index'),
		meta: {
			auth: 55
		}
	},
	{
		path: '/pc',
		redirect: '/pc/list',
		name: '投诉综合管理',
		icon: 'complaint',
		meta: {
			auth: 3
		},
		children: [
			{
				path: 'list',
				component: () => import('@/views/complaint/list'),
				name: 'pclist',
				meta: { title: '投诉管理', auth: 4 }
			},
			// {
			// 	path: 'add',
			// 	component: () => import('@/views/complaint/add'),
			// 	name: 'pcadd',
			// 	meta: { title: '投诉录入' },
			// 	hide: true
			// },
			// {
			// 	path: 'edit',
			// 	component: () => import('@/views/complaint/edit'),
			// 	name: 'pcedit',
			// 	meta: { title: '投诉编辑' },
			// 	hide: true
			// },
			{
				path: 'report',
				component: () => import('@/views/complaint/report'),
				meta: { title: '投诉数据报表', auth: 5 },
			},
		]
	},
	{
		path: '/contact',
		name: '值班值守管理',
		icon: 'onduty',
		meta: {
			auth: 6
		},
		children: [
			{
				path: 'list',
				component: () => import('@/views/contact/list'),
				name: 'contactList',
				meta: { title: '通讯录管理', auth: 7 },
			},
			// {
			// 	path: 'add',
			// 	component: () => import('@/views/contact/add'),
			// 	name: 'contactAdd',
			// 	meta: { title: '通讯录管理' },
			// 	hide: true
			// },
			{
				path: 'onDuty',
				component: () => import('@/views/onDuty/list'),
				meta: { title: '值排班管理', auth: 8 },
			}
		]
	},
	{
		path: '/warn',
		name: '综合监测预警',
		icon: 'warn',
		meta: {
			auth: 9
		},
		children: [
			{
				path: 'list',
				component: () => import('@/views/warn/list'),
				name: 'warnList',
				meta: {
					title: '气象&交通告警',
					auth: 10
				}
			},
			{
				path: 'message',
				component: () => import('@/views/warn/message'),
				name: 'message',
				hide: true,
				meta: {
					title: '查看短信记录', auth: 10
				},
			},
		]
	},
	{
		path: '/setting',
		name: '系统设置',
		icon: 'setting',
		meta: {
			auth: 11
		},
		children: [
			{
				path: 'user',
				component: () => import('@/views/setting/user'),
				meta: {
					title: '用户管理', auth: 12
				}
			},
			{
				path: 'work',
				component: () => import('@/views/setting/work'),
				meta: {
					title: '岗位管理', auth: 13
				},
			},
			{
				path: 'role',
				component: () => import('@/views/setting/role'),
				meta: {
					title: '角色管理', auth: 49
				},
			},
		]
	},
	{
		hide: true,
		path: '/homeSetting',
		name: '大屏',
		component: () => import('@/views/home/setting'),
	},
	{
		path: '/login',
		name: 'Login',
		component: () => import('@/views/login/index'),
		hide: true
	},
]


const createRouter = () => new Router({
	scrollBehavior: () => ({ y: 0 }),
	routes: constantRoutes.map(item => {
		if (!item.hide) {
			item.component = Index
		}
		return item
	})
})

const router = createRouter()

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err);
}

export function resetRouter() {
	const newRouter = createRouter()
	router.matcher = newRouter.matcher // reset router
}

router.beforeEach((to, from, next) => {
	if (to.path == '/login' || to.path == '/navigate') {
		return next()
	}
	let auth = Vue.prototype.$MenuAuth
	let btn = Vue.prototype.$ButtonAuth
	if (JSON.stringify(auth) == "{}") {
		userInfo().then(res => {
			res.data.menus.forEach(item => {
				auth[item.menuId] = true
				if (item.childs && item.childs.length) {
					item.childs.forEach(child => {
						auth[child.menuId] = true
						if (child.childs && child.childs.length) {
							child.childs.forEach(c => {
								btn[c.menuId] = true
							})
						}
					})
				}
			})
			Vue.prototype.$MenuAuth = auth
			Vue.prototype.$ButtonAuth = btn
			let authId = to.meta.auth
			if (auth[authId]) {
				return next()
			} else {
				Message({
					message: '您没有权限访问该页面',
					type: 'error',
					duration: 3 * 1000
				})
			}
		})
	} else {
		let authId = to.meta.auth
		if (auth[authId]) {
			return next()
		} else {
			Message({
				message: '您没有权限访问该页面',
				type: 'error',
				duration: 3 * 1000
			})
		}
	}

})

export default router
