<template>
  <div id="app" style="height: 100%;width: 100%;">
    <navbar />
    <div style="flex: 1;height: calc( 100% - 100px )" class="flex">
      <!-- 左侧菜单 -->
      <el-menu :default-active="activeIndex" @select="handleSelect" style="width: 200px;overflow-y: auto;">
        <el-submenu :index="item.index" v-for="(item, index) of menuList " :key="index">
          <template slot="title">
            <img :src="item.icon" style="width: 17px;height: 17px;margin-right: 5px;">
            <span>{{ item.name }}</span>
          </template>
          <el-menu-item-group v-for="(ele, inde) of item.children" :key="inde">
            <el-menu-item :index="ele.index">{{ ele.name }}</el-menu-item>
          </el-menu-item-group>
        </el-submenu>
      </el-menu>
      <transition name="el-fade-in">
        <div style="padding: 15px;height: calc(100vh - 100px);overflow-y: auto;width: 100%;box-sizing: border-box;">
          <router-view></router-view>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar'
import { constantRoutes } from '@/router';
export default {
  name: 'App',
  components: {
    Navbar
  },
  data() {
    return {
      activeIndex: '1',
      menuList: []
    }
  },
  created() {
    this.handleMenu()
  },
  methods: {
    //菜单选择
    handleSelect(key, keyPath) {
      const currentPath = this.$route.path
      if (currentPath === key) return
      this.$router.push({
        path: key
      })
    },
    //初始化菜单
    handleMenu() {
      //获取权限 
      let auth = this.$MenuAuth
      const currentPath = this.$route.path
      this.activeIndex = currentPath
      let menu = []
      menu.push({
        icon: require('../assets/index/home.png'),
        index: '/home',
        path: '/home',
        name: '驾驶舱',
        auth: 1,
        children: [
          {
            index: '/home',
            path: 'home',
            name: '调度数据中心',
            auth: 2,
          },
          {
            index: '/dispatch',
            path: 'dispatch',
            name: '应急指挥调度',
            auth: 55,
          },
        ]
      })
      //根据路由列表初始化
      constantRoutes.map(ele => {
        if (!ele.hide) {
          let children = []
          if (ele.children) {
            ele.children.map(item => {
              if (!item.hide) {
                children.push({
                  index: ele.path + '/' + item.path,
                  path: item.path,
                  name: item.meta.title,
                  auth: item.meta.auth,
                })
              }
            })
          }
          menu.push({
            icon: require('../assets/index/' + ele.icon + '.png'),
            index: ele.path,
            path: ele.path,
            name: ele.name,
            auth: ele.meta.auth,
            children
          })
        }
      })
      //权限校验
      menu = menu.filter(item => auth[item.auth])
      menu = menu.map(item => {
        item.children = item.children.filter(ele => auth[ele.auth])
        return item
      })
      this.menuList = menu
    }
  },
}
</script>
<style lang="scss"></style>
