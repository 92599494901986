
import axios from 'axios'

import request from '@/utils/request'
// import download from '@/utils/download'
import { exportExcel } from '@/utils/download'
//获取字典
export function getDict(data) {
  return request({
    url: '/wenlv-api/common/dict?kvType=' + data,
    method: 'get',
  })
}
//添加投诉
export function addComplaint(data) {
  return request({
    url: '/wenlv-api/complaint/insert',
    method: 'post',
    data: data
  })
}
//编辑投诉
export function editComplaint(id, data) {
  return request({
    url: '/wenlv-api/complaint/update/' + id,
    method: 'post',
    data: data
  })
}
// 投诉详情
export function infoComplaint(id) {
  return request({
    url: '/wenlv-api/complaint/' + id,
    method: 'get',
  })
}
// 投诉进度
export function progressComplaint(id) {
  return request({
    url: '/wenlv-api/complaint/progress/log/' + id,
    method: 'get',
  })
}
//删除投诉
export function delComplaint(id) {
  return request({
    url: '/wenlv-api/complaint/del/' + id,
    method: 'get',
  })
}
//投诉列表
export function complaintList(data) {
  return request({
    url: '/wenlv-api/complaint/search',
    method: 'post',
    data: data
  })
}

//认领
export function receive(id) {
  return request({
    url: '/wenlv-api/complaint/receive/' + id,
    method: 'get',
  })
}
//反馈
export function feedback(id, data) {
  return request({
    url: '/wenlv-api/complaint/feedback/' + id,
    method: 'post',
    data: data
  })
}
//投诉总数
export function getAmount() {
  return request({
    url: '/wenlv-api/complaint/stat',
    method: 'get'
  })
}
//上传文件
export function upload(type, file) {
  let data = new FormData()
  data.append('file', file);
  let token = localStorage.getItem('token')
  return axios.post(`/wenlv-api/common/${type}/upload`, data, {
    headers: {
      'token': token,
      'source': 'web',
      'Content-Type': 'multipart/form-data'
    }
  })
  return Promise.resolve()
}
//登录
export function login(data) {
  return request({
    url: '/wenlv-api/user/login',
    method: 'post',
    data: data
  })
}
export function loginBySms(data) {
  return request({
    url: '/wenlv-api/user/mobile/login',
    method: 'post',
    data: data
  })
}
export function getSmsCode(data) {
  return request({
    url: '/wenlv-api/user/mobile/code?mobile=' + data,
    method: 'get',
    data: data
  })
}
//登出
export function logout(data) {
  return request({
    url: '/wenlv-api/user/logout',
    method: 'get',
    data: data
  })
}
//获取验证码
export function getCodeImg() {
  return request({
    url: '/wenlv-api/user/verifyCode',
    method: 'get',
  })
}
//投诉状态统计
export function complaintStat() {
  return request({
    url: '/wenlv-api/complaint/data/stat',
    method: 'get',
  })
}
//投诉数据月度分析
export function complaintStatMonth() {
  return request({
    url: '/wenlv-api/complaint/data/stat/month',
    method: 'get',
  })
}
//投诉调度数据分析
export function complaintStatMonthDispatch() {
  return request({
    url: '/wenlv-api/complaint/data/stat/month/dispatch',
    method: 'get',
  })
}
//投诉类型占比
export function complaintStatRate(type) {
  return request({
    url: `/wenlv-api/complaint/data/stat/type/${type}/rate`,
    method: 'get',
  })
}
//初始化投诉单位排名
export function complaintDispatchTop(type) {
  return request({
    url: `/wenlv-api/complaint/data/stat/dispatch/frequency/${type}/top`,
    method: 'get',
  })
}
//初始化投诉类型排名
export function complaintStatSort() {
  return request({
    url: `/wenlv-api/complaint/data/stat/type/sort`,
    method: 'get',
  })
}

//初始化重点投诉监控
export function complaintStatPoint() {
  return request({
    url: `/wenlv-api/complaint/data/stat/point/sort`,
    method: 'get',
  })
}


export function complaintExport(data) {
  return request({
    url: '/wenlv-api/complaint/export',
    method: 'post',
    data: data,
    responseType: 'blob',
  })
}
//用户信息
export function userInfo() {
  return request({
    url: `/wenlv-api/user/info`,
    method: 'get',
  })
}