<template>
  <div id="app">
    <router-view>
    </router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  beforeMount() {
    let list = process.env.VUE_APP_LIST,
      add = process.env.VUE_APP_ADD
    //获取当前地址
    let location = window.location.href
    //判断地址 如果有mobile/list跳转到投诉h5列表 如果有mobile/add跳转到h5投诉添加
    if (location.includes('mobile/list')) {
      window.location.href = list
    } else if (location.includes('mobile/add')) {
      window.location.href = add
    }
  },
}
</script>
<script type="text/javascript">

    window._AMapSecurityConfig = {
 
      securityJsCode: "340cb84b9eab67dfc5a451b2678aa001",
 
}
</script>
<style>
body {
  margin: 0;
}

.space {
  display: flex;
  justify-content: space-between;
}

.align {
  display: flex;
  align-items: center;
}

.wrap {
  display: flex;
  flex-wrap: wrap;
}

.flex {
  display: flex;
}

.flexCenter {
  display: flex;
  justify-content: center;
  align-items: center
}
 
</style>
