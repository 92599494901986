import axios from 'axios'
import { Message } from 'element-ui'
import Router from '@/router'

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 30000
})
service.interceptors.request.use(
  config => {
    if (true) {
      let token = localStorage.getItem('token')
      config.headers['token'] = token
      config.headers['source'] = 'web'
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)
service.interceptors.response.use(
  response => {
    const res = response.data
    if (res.code && res.code !== 200) {
      if (res.code === 9999) {
        let path = Router.currentRoute.path;
        if (path !== '/login') {
          Router.push({ path: '/login' });
        }
        return
      }
      else {
        Message({
          message: res.message,
          type: 'error',
          duration: 3 * 1000
        })
      }
      return Promise.reject(new Error(res.message || 'Error'))
    } else {
      return res
    }
  },
  error => {
    let errorMessage = error.message;
    if (error.response.status === 403) {
      errorMessage = "您没有权限访问该接口"
    }
    Message({
      message: errorMessage,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)
export default service


